import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "gatsby-theme-stack/src/integration-layout";
export const _frontmatter = {
  "title": "Twitter",
  "author": "admin",
  "date": "2019-01-01T00:00:00.000Z",
  "image": "img/twitter.svg"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Twitter DM`}</h1>
    <p>{`Reply to Twitter messages from Crisp`}</p>
    <hr></hr>
    <p>{`This integration lets you support your customers from Twitter. Connect Crisp to your brand's Twitter account and receive direct messages sent by your customers. You can now handle all your Twitter DM queries with a Shared Inbox and reply from any device.`}</p>
    <p>{`Benefits of using Crisp with Twitter:`}</p>
    <ul>
      <li parentName="ul">{`Avoid distraction by keeping all your Twitter DM in Crisp`}</li>
      <li parentName="ul">{`Be more productive with the Crisp Inbox features (Knowledge Base, Shortcuts, Assignments)`}</li>
      <li parentName="ul">{`Get your user's information (Avatar, Full-name)`}</li>
      <li parentName="ul">{`Integrate it with your team tools`}</li>
      <li parentName="ul">{`Preserves most messaging features, typing indicators, file-sharing, GIFs and emojis`}</li>
      <li parentName="ul">{`Build a Twitter bot a in few clicks with the Crisp bot feature`}</li>
      <li parentName="ul">{`Messages you receive are stored in the Crisp dashboard. Your chat history is safe.`}</li>
      <li parentName="ul">{`Use the Crisp search engine to search previous conversations`}</li>
    </ul>



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      